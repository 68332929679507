<template>
    <workspace-wrapper>
        <template #workspace>
            <dice-workspace :result="stateData.currentSpinResult.result"
                            :payout="stateData.currentSpinResult.payout"
                            :total-win="stateData.currentSpinResult.totalWin"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import DiceWorkspace from "./DiceWorkspace.vue";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        result: 0.0,
                        payout: 0.0,
                        totalWin: 0.0,
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        DiceWorkspace
    }
}
</script>

<style scoped>

</style>

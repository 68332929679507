<template>
    <div>
        <div :style='{backgroundImage: `url(${backgroundImagePath})`}' class='layout background'></div>
        <div id="point__holder" class="top_left">
            <div :style='{backgroundImage: `url(${pointImagePath})`}' class='point'></div>
        </div>
        <svg class='top_left' width='668' height='336' xmlns='http://www.w3.org/2000/svg'>
            <path id='arc__orange' d="" stroke='#ed7620' fill='transparent'
                  stroke-width='17'/>
            <path id='arc__green' d="" stroke='green' fill='transparent'
                  stroke-width='16'/>
        </svg>
        <div class="layout top_left">
            <p class="result">{{ result }}</p>
            <p class="selected" :style='{backgroundColor: selected.color}'>
                {{ selected.text }}: <span>{{ displayedWinChance }}</span>
            </p>
        </div>
    </div>
</template>

<script>
const rollUnder = "Roll Under";
const rollOver = "Roll Over";

export default {
    props: {
        result: {
            type: Number,
            required: true,
        },
        payout: {
            type: Number,
            required: true
        },
        totalWin: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            green: {
                startX: 40,
                startY: 335,
                endX: 629,
                endY: 335,
                direction: 1,
                radius: 294.5,
                startAngle: Math.PI,
            },
            orange: {
                endX: 8.5,
                endY: 335,
                startX: 659.5,
                startY: 335,
                startAngle: 0,
                direction: 0,
                radius: 325.5,
            },
            point: {
                startX: 24,
                startY: 335,
                endX: 644,
                endY: 335,
                radius: 310,
                startAngle: Math.PI,
            },
            under: {
                text: rollUnder,
                color: "green"
            },
            over: {
                text: rollOver,
                color: "orange",
            }
        }
    },
    computed: {
        backgroundImagePath() {
            return require(`@/assets/games/mini/dice/background.png`);
        },
        pointImagePath() {
            return require(`@/assets/games/mini/dice/point.png`);
        },
        winChance() {
            return Math.floor(97 / this.payout * 100) / 100;
        },
        winChanceOver() {
            return Number.parseFloat((100 - this.winChance).toFixed(2));
        },
        selected() {
            if (this.totalWin) {
                return this.result > this.winChance ? this.over : this.under;
            } else {
                return this.result > this.winChance ? this.under : this.over;
            }
        },
        displayedWinChance() {
            if (this.selected.text === rollOver) {
                return this.winChanceOver;
            } else {
                return this.winChance;
            }
        },
    },
    methods: {
        getArcPos: (data, percent) => {
            const angle = Math.PI * percent / 100.0 + data.startAngle;
            const centerX = (data.endX + data.startX) / 2;
            const centerY = (data.endY + data.startY) / 2;
            const x = centerX + data.radius * Math.cos(angle);
            const y = centerY + data.radius * Math.sin(angle);
            return {x, y};
        },
        getPathString: function (data, percent) {
            const {x, y} = this.getArcPos(data, percent);
            return `M ${data.startX} ${data.startY} A ${data.radius} ${data.radius} 0 0 ${data.direction} ${x} ${y}`;
        }
    },
    mounted() {
        const width = 668;
        const height = 336;
        this.$store.dispatch('workspace/setHeight', height);
        this.$store.dispatch('workspace/setWidth', width);

        const orangeArc = document.getElementById('arc__orange');
        orangeArc.setAttribute('d', this.getPathString(this.orange, -this.winChance));

        const greenArc = document.getElementById('arc__green');
        greenArc.setAttribute('d', this.getPathString(this.green, this.winChance));

        const point = document.getElementById("point__holder");
        const {x, y} = this.getArcPos(this.point, this.result);
        point.style.left = `${x}px`;
        point.style.top = `${y}px`;
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    margin: auto;
}

td {
    border: 1px solid #dee2e6 !important;
}

.background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.point {
    position: relative;
    top: -9px;
    left: -9px;
    width: 18px;
    height: 18px;
}

.top_left {
    position: absolute;
    top: 0;
    left: 0;
}

.layout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 668px;
    height: 336px;
}

.result {
    font-size: 48px;
}

.selected {
    color: white;
    padding: 20px;
}


</style>
